
import { Box, Grid } from '@mui/material';

import ContactUs from './ContactUs';



const ContactUsPage = () => {


    return (

        <Box>
            <Grid container>
                <Grid item xs={12}
                    sx={{
                        height: '350px',
                        backgroundImage: 'url(./images/background/about-bg.jpg)',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                    }}>

                </Grid>
                <Grid item xs={12}>
                    <ContactUs />
                        
                </Grid>
            </Grid>



        </Box >



    );
}

export default ContactUsPage;