
import { Box, Grid, Container, Typography } from '@mui/material';

const AboutUs = () => {


    return (

        <Box>
            <Grid container>
                <Grid item xs={12}
                    sx={{
                        height: '350px',
                        backgroundImage: 'url(./images/background/about-bg.jpg)',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                    }}>

                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth="lg">
                        <Typography variant="h1" color="text.primary" sx={{ my: 5 }} data-aos="fade-up">
                            About Us
                        </Typography>
                        <Typography variant='body1' sx={{ mb: 1 }}>
                            TrueLines RCM Solutions is a leading provider of Revenue Cycle Management (RCM) and
                            Health Information Management (HIMS) solutions to Healthcare Providers (Medical Billing
                            companies, Hospitals, Faculty Practices, ASCs, LTCs, etc.) and Pharmacies.
                            TrueLines RCM Solutions was established in 2017 with an aim of providing high-quality
                            patient care documentation to hospitals, specialty groups, and general practice groups
                            throughout the US.</Typography>
                        <Typography variant='body1' sx={{ mb: 1 }}>
                            A leading US healthcare revenue cycle management company with 5+ years of expertise in
                            the RCM domain. Helping physicians make more revenue.
                            Our approach is unique; no interfaces are needed. We are system-agnostic and customize
                            our approach to work with your organization to free up valuable resources and maximize
                            revenue.</Typography>
                        <Typography variant='h4' sx={{ mb: 1 }}>
                            Ease your administrative and financial burdens
                        </Typography>
                        <Typography variant='body1' sx={{ mb: 1 }}>
                            As providers continue to face increased revenue cycle challenges, having a partner that truly
                            understands the best way to address the financial burdens they face is imperative. TrueLines
                            RCM Solutions works with healthcare organizations to maximize cash at every stage of the
                            revenue cycle. Leverage technology and business intelligence that will allow you to make
                            informed decisions.
                        </Typography>
                    </Container>

                </Grid>
            </Grid>



        </Box >



    );
}

export default AboutUs;