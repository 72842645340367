import { Container, Box, Grid, Paper, Typography } from '@mui/material';


import ContactUs from "./ContactUs";


function MainPage() {

    return (
        <>
            <Box sx={{
                height: '100vh',
                backgroundImage: 'url(./images/background/main-bg.jpg)',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}
            >
                <Grid
                    container
                    alignItems="center"
                    sx={{
                        height: '100%',
                        boxShadow: '0 0 200px rgba(0,0,0,0.9) inset'
                    }}>
                    <Grid item xs={12}>
                        <Container maxWidth="md" sx={{ textAlign: 'center' }}>
                            <Box data-aos="fade-up"
                                sx={{ width: 'fit-content', background: 'rgba(0,0,0,0.3)', margin: 'auto', px:5 }}
                            >
                                <Typography
                                    variant="h1" color="text.white"
                                    sx={{ mt: 1, fontSize: { xs: '4rem', sm: '6rem' }, fontWeight: '700' }}>
                                    a transparent
                                </Typography>

                            </Box>
                            <Box data-aos="fade-up" data-aos-delay="200"
                                sx={{ width: 'fit-content', background: 'rgba(0,0,0,0.3)', margin: 'auto', px:5 }}
                            >
                                <Typography
                                    variant="h1" color="text.white"
                                    sx={{ mt: 1, fontSize: { xs: '4rem', sm: '6rem' }, fontWeight: '700' }}>
                                    healthcare solution!
                                </Typography>

                            </Box>


                        </Container>

                    </Grid>
                    {/*<Grid item container justifyContent="flex-end" md={6} sx={{ height: '100%', display: { xs: 'none', md: 'flex' } }} data-aos="fade-left">
                        {<img src='/images/slider-img.png' alt="Makclubs" />}
                    </Grid>*/}
                </Grid>
            </Box>

            <Box>
                <Grid container>
                    <Grid item xs={12} md={4} sx={{ p: 6, backgroundColor: '#e9e9e9', textAlign: 'center' }}>
                        {<img src="./images/vision.png" alt="vision" width="75" />}
                        <Typography variant="h3" sx={{ my: 2 }}>Vision</Typography>
                        <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'justify' }}>
                            Our Vision is to create a transparent healthcare solution with upgraded automation &amp;
                            technology that ensures progressive relationship with our Business partners.
                        </Typography>

                    </Grid>
                    <Grid item xs={12} md={4} sx={{ p: 6, textAlign: 'center' }}>
                        {<img src="./images/info.png" alt="truelines" width="75" />}
                        <Typography variant="h3" sx={{ my: 2 }}>Truelines</Typography>
                        <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'justify' }}>A leading provider of Revenue Cycle Management (RCM) and
                            Health Information Management solutions to Healthcare Providers and Pharmacies.
                        </Typography>

                    </Grid>
                    <Grid item xs={12} md={4} sx={{ p: 6, backgroundColor: 'primary.main', textAlign: 'center' }}>
                        {<img src="./images/service.png" alt="service" width="75" />}
                        <Typography variant="h3" color="text.white" sx={{ my: 2 }}>Services</Typography>
                        <Box color="text.white" sx={{ textAlign: 'center' }}>
                            <Typography variant="body1">Billing and AR Services</Typography>
                            <Typography variant="body1">Front-End Fixes</Typography>
                            <Typography variant="body1">Transaction Processing</Typography>
                            <Typography variant="body1">A/R Management</Typography>
                        </Box>

                    </Grid>
                </Grid>
            </Box>

            <Box>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant='h2' color="primary.main" sx={{ mt: 5, py: 5, textAlign: 'center' }}>
                            REVENUE CYCLE MANAGEMENT
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ p: 5 }}>
                        {<img src='/images/rcm.jpg' alt="RCM" />}

                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ py: 5 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <Paper elevation={3} sx={{ mb: 3, p: 2 }}>
                                    <Typography variant='h5'>1. Patient Access Management</Typography>
                                    <Typography variant='body1'>Scheduling &amp; Registration</Typography>
                                    <Typography variant='body1'>Eligibility &amp; Benefits Verification</Typography>

                                </Paper>
                                <Paper elevation={3} sx={{ mb: 3, p: 2 }}>
                                    <Typography variant='h5'>3. Billing</Typography>
                                    <Typography variant='body1'>Demographic Entry</Typography>
                                    <Typography variant='body1'>Charge Entry</Typography>
                                    <Typography variant='body1'>Transmission</Typography>
                                    <Typography variant='body1'>Cash Posting</Typography>
                                    <Typography variant='body1'>Credit Balance Report</Typography>

                                </Paper>
                                <Paper elevation={3} sx={{ mb: 3, p: 2 }}>
                                    <Typography variant='h5'>5. AR Follow-up</Typography>
                                    <Typography variant='body1'>Account Receivable Services</Typography>
                                    <Typography variant='body1'>Insurance follow-up</Typography>

                                </Paper>

                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Paper elevation={3} sx={{ mb: 3, p: 2 }}>
                                    <Typography variant='h5'>2. Medical Coding</Typography>
                                    <Typography variant='body1'>Outpatient Visits</Typography>
                                    <Typography variant='body1'>Clinical Visits</Typography>
                                    <Typography variant='body1'>Emergency Department</Typography>
                                    <Typography variant='body1'>Same Day Surgery</Typography>
                                    <Typography variant='body1'>Inpatient</Typography>

                                </Paper>

                                <Paper elevation={3} sx={{ mb: 3, p: 2 }}>
                                    <Typography variant='h5'>4. Denial Management</Typography>
                                    <Typography variant='body1'>Weekly Review to check on Denials</Typography>

                                </Paper>

                            </Grid>

                        </Grid>


                    </Grid>
                </Grid>
            </Box>

            <Box
                sx={{
                    backgroundColor: 'primary.main',
                    py: 12, mt: 12, textAlign: 'center'
                }}
            >
                <Typography variant="h1" color="text.white" sx={{ mb: 3 }} data-aos="fade-up">
                    Our Mission
                </Typography>

                <Typography variant="h5" color="text.white" sx={{ mb: 2 }} data-aos="fade-up">
                    Consistently provide our clients with outstanding and highest level of quality services.</Typography>
                <Typography variant="h5" color="text.white" sx={{ mb: 2 }} data-aos="fade-up">Effective and open line communication with our Internal and External partners.</Typography>
                <Typography variant="h5" color="text.white" sx={{ mb: 2 }} data-aos="fade-up">Provide Solution that extract actionable insights and that improve our client revenue cycles.</Typography>
                <Typography variant="h5" color="text.white" sx={{ mb: 2 }} data-aos="fade-up">Develop efficient and effective resources with RCM knowledge and in compliance with
                    applicable laws and regulations.
                </Typography>
            </Box>


            <ContactUs />
        </>
    );
}

export default MainPage;