import { Link } from 'react-router-dom';

import { Box, Grid, Container, Stack, Typography } from '@mui/material';

import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import BusinessIcon from '@mui/icons-material/Business';

import Copyright from "./Copyright";


const Footer = () => {

    return (

        <Box
            sx={{
                mt: 5,
                backgroundColor: '#363636'
            }}
        >

            <Container maxWidth="lg">
                <Grid container sx={{ pt: 3 }}>

                    <Grid item xs={12}>
                        <Box component={Link} to="/" sx={{ textDecoration: 'none' }}>
                            {<img alt="Truelines" src="../images/logo.png" height={50} />}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} >

                        <Stack spacing={2}>


                            <Stack direction="row">
                                <PhoneInTalkIcon color="secondary" fontSize="small" sx={{ mr: 1 }} />
                                <Stack>
                                    <Typography variant="body2" color="text.secondary">US: (314)270-1430</Typography>
                                    <Typography variant="body2" color="text.secondary">(762)278-2550</Typography>
                                </Stack>

                            </Stack>
                            {/*<Stack direction="row">
                                <PhoneInTalkIcon color="secondary" fontSize="small" sx={{ mr: 1 }} />
                                <Typography variant="body2" color="text.secondary">US: (762)278-2550</Typography>
                            </Stack>*/}
                            <Stack direction="row">
                                <MailOutlineIcon color="secondary" fontSize="small" sx={{ mr: 1 }} />
                                <Typography variant="body2" color="text.secondary">truelinesrcmsolutions@gmail.com</Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <Stack>
                            <Stack direction="row">
                                <BusinessIcon color="secondary" fontSize="small" sx={{ mr: 1 }} />
                                <Typography color="text.secondary">TrueLines RCM Solutions LLC</Typography>
                            </Stack>

                            <Typography variant="body2" color="text.secondary" sx={{ pl: 3.5 }}>1017 Golden Orchard Dr</Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ pl: 3.5 }}>O'Fallon</Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ pl: 3.5 }}>Missouri 63368 USA</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <Stack sx={{ pl: 3.5 }}>
                            <Typography color="text.secondary">SERVICES</Typography>

                            <Typography variant="body2" color="text.secondary">Billing and AR Services</Typography>
                            <Typography variant="body2" color="text.secondary">Front-End Fixes</Typography>
                            <Typography variant="body2" color="text.secondary">Transaction Processing</Typography>
                            <Typography variant="body2" color="text.secondary">A/R Management</Typography>
                        </Stack>
                    </Grid>


                </Grid>

            </Container>
            <Box item xs={12} sx={{ mt: 3, p: 5, backgroundColor: '#3f3f3f' }}>
                <Copyright />
            </Box>
        </Box>
    )
}

export default Footer;