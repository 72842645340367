import { createTheme } from "@mui/material/styles";
import { purple, red } from '@mui/material/colors';

import Typography from './typography';
import Shadows from "./shadows";

const themeTypography = Typography(`'Mulish', sans-serif`);

const themeShadows = Shadows();

const theme = createTheme({
    palette: {
        primary: {
            main: '#00A8EA',
            lighter:purple[50],
            contrastText: '#fff'
        },
        secondary: {
            main: '#969696',
        },
        text: {
            primary: '#383838',
            secondary: '#969696',
            white: '#fff',
            red: red['A400'],
        },
        white: {
            main: '#fff',
        },
        green: {
            main: '#17cc10',
            lighter: '#e7f9e7',
            contrastText: '#ffffff',
            
        },
        orange: {
            main: "#fca733",
            lighter: "#fee9cc",
            contrastText: "#ffffff",
        },
        blue: {
            main: '#0f8cff',
            lighter: '#57aeff',
            contrastText: '#fff'
        },
        red: {
            main: red['A400'],
            contrastText: '#fff',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 'bold',
                    textTransform: "uppercase",
                },
            },
        },
    },

    typography: themeTypography,
    shadows: themeShadows
});

export default theme;