import { Link } from 'react-router-dom';

import Typography from "@mui/material/Typography";

const Copyright = (props) => {
    return (
        <Typography variant="body2" color="#808285" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" to="https://makclubs.com">
                Truelines RCM Solutions LLC.
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default Copyright;