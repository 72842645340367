
import { Box, Grid, Container, Typography, Divider } from '@mui/material';



const VisionMission = () => {


    return (

        <Box sx={{ mt: 10 }}>
            <Grid container>
                <Grid item xs={12}
                    sx={{
                        height: '350px',
                        backgroundColor: '#00A8EA',
                        backgroundImage: 'url(./images/background/hipaa-bg.jpg)',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center'
                    }}>

                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth="lg">
                        <Grid container sx={{ mt: 8 }}>
                            <Grid item xs={12} md={6} sx={{ px:10, py: 5 }} data-aos="fade-right">
                                {<img src="./images/background/vision-bg.png" alt="mission" />}
                            </Grid>
                            <Grid item xs={12} md={6} data-aos="fade-left"
                                sx={{ display:'flex', flexDirection:'column', justifyContent:'center' }}
                            >
                                <Typography variant="h1" color="text.primary" sx={{ my: 3 }}>
                                    Our Vision
                                </Typography>
                                <Typography variant='body1' sx={{ mb: 5 }}>
                                    Our Vision is to create a transparent healthcare solution with upgraded automation &amp;
                                    technology that ensures progressive relationship with our Business partners.</Typography>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container sx={{ mt: 8 }}>
                            <Grid item xs={12} md={6} data-aos="fade-right">
                                <Typography variant="h1" color="text.primary" sx={{ my: 3 }}>
                                    Our Mission
                                </Typography>
                                <Typography variant='body1' sx={{ mb: 1 }}>
                                    Consistently provide our clients with outstanding and highest level of quality services.
                                </Typography>
                                <Typography variant='body1' sx={{ mb: 1 }}>
                                    Effective and open line communication with our Internal and External partners.
                                </Typography>
                                <Typography variant='body1' sx={{ mb: 1 }}>
                                    Provide Solution that extract actionable insights and that improve our client revenue cycles.
                                </Typography>
                                <Typography variant='body1' sx={{ mb: 1 }}>
                                    Develop efficient and effective resources with RCM knowledge and in compliance with
                                    applicable laws and regulations.
                                </Typography>

                            </Grid>
                            <Grid item xs={12} md={6} sx={{ px:10, py: 5 }} data-aos="fade-left">
                                {<img src="./images/background/mission-bg.png" alt="mission" />}
                            </Grid>
                        </Grid>


                    </Container>

                </Grid>
            </Grid>



        </Box >



    );
}

export default VisionMission;