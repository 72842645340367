const Typography = (fontFamily) => ({
    htmlFontSize: 10,
    fontFamily,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
        fontWeight: 900,
        fontSize: '3.8rem',
        lineHeight: 1.6,
        textTransform: 'capitalize'
    },
    h2: {
        fontWeight: 600,
        fontSize: '3.2rem',
        lineHeight: 1.4,
        textTransform: 'capitalize'
    },
    h3: {
        fontWeight: 600,
        fontSize: '2.4rem',
        lineHeight: 1.4,
        textTransform: 'capitalize'
    },
    h4: {
        fontWeight: 600,
        fontSize: '2rem',
        lineHeight: 1.4,
        textTransform: 'capitalize'
    },
    h5: {
        fontWeight: 600,
        fontSize: '1.6rem',
        lineHeight: 1.4,
        textTransform: 'capitalize'
    },
    h6: {
        fontWeight: 400,
        fontSize: '1.2rem',
        lineHeight: 1.4,
        textTransform: 'capitalize'
    },
    caption: {
        fontWeight: 400,
        fontSize: '1.5rem'
    },
    body1: {
        fontSize: '1.8rem',
        lineHeight: 2
    },
    body2: {
        fontSize: '1.6rem',
        lineHeight: 1.66
    },
    subtitle1: {
        fontSize: '1.1rem',
        fontWeight: 600,
        lineHeight: 1.57
    },
    subtitle2: {
        fontSize: '2.4rem',
        fontWeight: 500,
        lineHeight: 1.5,
        fontStyle: 'oblique'
    },
    overline: {
        lineHeight: 1.66
    },
    button: {
        textTransform: 'capitalize'
    }
});

export default Typography;