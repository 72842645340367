import { useFormik } from "formik";
import * as yup from 'yup';


import { Box, Grid, Paper, Container, TextField, Button, Stack, Typography } from '@mui/material';

import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import BusinessIcon from '@mui/icons-material/Business';

const validationSchema = yup.object({
    name: yup.string()
        .required('name required'),
    email: yup.string()
        .email('invalid email')
        .required('email required'),
    phoneNumber: yup.string()
        .required('contact number required'),
    message: yup.string()
        .required("message required"),
});

const ContactUs = () => {

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phoneNumber: '',
            message: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            //await api.contactUs(values);
        },
    });


    return (

        <Box
            sx={{
                mt: 12,
                backgroundImage: 'url(/images/background/contact-us-bg.png)',
                backgroundPosition: 'right bottom',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat'
            }}>
            <Container maxWidth="lg">
                <Grid container>
                    <Grid item xs={12}>
                        <Box sx={{ textAlign: 'center' }} data-aos="fade-up">
                            <Typography variant="h5" color="primary.main">Any question?</Typography>
                            <Typography variant="h1" color="text.primary">
                                Contact Us
                            </Typography>
                            <Typography variant="body">
                                Leave us a message. We'll get back to you soon.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ mt:5, textAlign:'center' }}>
                        <Paper elevation={3} sx={{ maxWidth: '500px', p: 5 }} data-aos="fade-up">

                            <Box component="form" onSubmit={formik.handleSubmit} >
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="name"
                                            label="Name"
                                            autoComplete="name"
                                            fullWidth
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            error={formik.touched.name && Boolean(formik.errors.name)}
                                            helperText={formik.touched.name && formik.errors.name}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>

                                        <TextField
                                            name="email"
                                            label="Email ID"
                                            autoComplete="email"
                                            fullWidth
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            error={formik.touched.email && Boolean(formik.errors.email)}
                                            helperText={formik.touched.email && formik.errors.email}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>

                                        <TextField
                                            name="phoneNumber"
                                            label="Contact Number"
                                            autoComplete="phone"
                                            fullWidth
                                            value={formik.values.phoneNumber}
                                            onChange={formik.handleChange}
                                            error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                        />

                                    </Grid>
                                    <Grid item xs={12}>

                                        <TextField
                                            name="message"
                                            label="Message"
                                            autoComplete="message"
                                            fullWidth
                                            multiline
                                            minRows={2}
                                            maxRows={5}
                                            value={formik.values.message}
                                            onChange={formik.handleChange}
                                            error={formik.touched.message && Boolean(formik.errors.message)}
                                            helperText={formik.touched.message && formik.errors.message}
                                        />

                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack direction="row" spacing={2}>
                                            <Button
                                                variant="contained"
                                                size="large"
                                                type="submit"
                                            >
                                                Submit
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                size="large"
                                                onClick={formik.resetForm}
                                            >
                                                Clear
                                            </Button>

                                        </Stack>

                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>

                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ px:{ xs:2, sm:10 }, mt:5 }}>
                        <Stack spacing={1} data-aos="fade-up">
                            <Stack direction="row">
                                <BusinessIcon color="primary" fontSize="small" sx={{ mr: 1 }} />
                                <Typography variant="h5" color="primary">Truelines RCM Solutions LLC</Typography>
                            </Stack>

                            <Typography variant="body2" color="text.secondary" sx={{ pl: 3.5 }}>1017 Golden Orchard Dr</Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ pl: 3.5 }}>O'Fallon</Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ pl: 3.5 }}>Missouri 63368 USA</Typography>

                            <Stack direction="row">
                                <PhoneInTalkIcon color="primary" fontSize="small" sx={{ mr: 1 }} />
                                <Typography variant="body2" color="text.secondary">United States: (314)270-1430, (762)278-2550</Typography>
                            </Stack>
                            {/*<Stack direction="row">
                                <PhoneInTalkIcon color="primary" fontSize="small" sx={{ mr: 1 }} />
                                <Typography variant="body2" color="text.secondary">India: (892)148-2175</Typography>
                            </Stack>*/}
                            <Stack direction="row">
                                <MailOutlineIcon color="primary" fontSize="small" sx={{ mr: 1 }} />
                                <Typography variant="body2" color="text.secondary">truelinesrcmsolutions@gmail.com</Typography>
                            </Stack>
                        </Stack>



                    </Grid>

                </Grid>


            </Container>

        </Box >
    );
}

export default ContactUs;