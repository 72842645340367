import { cloneElement, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import MenuIcon from '@mui/icons-material/Menu';
import { Menu, MenuItem } from '@mui/material';



const menuitems = [
    { name: 'Company', url: '/about-us'},
    { name: 'Vision & Mission', url: '/vision-and-mission' },
    { name: 'Services', url: '/services' },
    { name: 'Careers', url: '/careers'},
    {name: 'Contact Us', url: 'contact-us' }
];

function ElevationScroll(props) {
    const { children, triggerState } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });


    return cloneElement(children, {
        elevation: (triggerState || trigger) ? 1 : 0,
        style: {
            background: "#fff",
        }
    });

}


const MenuButton = ({ menu, menuItems }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
      };

    return (
        <>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                {menu}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem component={Link} to='/about-us' onClick={handleClose}>About Us</MenuItem>


            </Menu>
        </>
    );

}


const Navbar = (props) => {

    const location = useLocation();

    const { triggerProp } = props;

    const [drawer, setDrawer] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) { return; }
        setDrawer(open);
    };




    return (

        <ElevationScroll triggerState={triggerProp || false}>
            <AppBar>
                <Container maxWidth="xl">
                    <Toolbar disableGutters sx={{ height: '85px' }}>

                        <Box flexGrow={1} sx={{ display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={toggleDrawer(true)}
                            >
                                <MenuIcon />
                            </IconButton>

                            <SwipeableDrawer
                                open={drawer}
                                onClose={toggleDrawer(false)}
                                onOpen={toggleDrawer(true)}
                            >
                                <Box
                                    sx={{ width: 250 }}
                                    role="presentation"
                                    onClick={toggleDrawer(false)}
                                    onKeyDown={toggleDrawer(false)}
                                >
                                    <Toolbar
                                        sx={{
                                            my: 3,
                                            height: 50,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        {<img alt="Truelines" src="../images/logo.png" />}
                                    </Toolbar>
                                    <List>
                                        {menuitems.map((menuitem) => (
                                            <ListItem key={menuitem.name} disablePadding>
                                                <ListItemButton
                                                    component={Link}
                                                    to={menuitem.url}
                                                    selected={menuitem.url === location.pathname.toLowerCase()}
                                                    sx={{
                                                        '&:hover': {
                                                            bgcolor: 'primary.lighter'
                                                        },
                                                        '&.Mui-selected': {
                                                            bgcolor: 'primary.lighter',
                                                            borderRight: "2px solid",
                                                            borderColor: "primary.main",
                                                            color: "primary.main",
                                                            '&:hover': {
                                                                color: "primary.main",
                                                                bgcolor: 'primary.lighter'
                                                            }
                                                        }
                                                    }}>
                                                    <ListItemIcon>
                                                        {menuitem.icon}
                                                    </ListItemIcon>
                                                    <ListItemText primary={menuitem.name} />
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>

                                </Box>
                            </SwipeableDrawer>
                        </Box>

                        <Box flexGrow={1} sx={{ height: 65, mr: 2 }} >
                            <Box component={Link} to="/" sx={{ textDecoration: 'none' }}>
                                {<img alt="Truelines" src="../images/logo.png" height={65} />}
                            </Box>
                        </Box>

                        <Box sx={{ mr: 5, display: { xs: 'none', md: 'flex' } }}>

                            <Button
                                component={Link}
                                to="/"
                            >
                                Home
                            </Button>

                            <MenuButton menu='Company' menuItems={[
                                { name: 'About us', link: 'about-us' }
                            ]} />

                            <Button
                                component={Link}
                                to="/vision-and-mission"
                            >
                                Vision & Mission
                            </Button>

                            {/*<MenuButton menu='Services'
                                menuItems={[
                                    { name:'Billing and AR Services', link:'billing-and-ar-services' },
                                    { name:'Front-End Fixes', link: 'front-end-fixes' },
                                    { name: 'Transaction Processing', link: 'transaction-processing' },
                                    { name: 'A/R Management', link:'ar-management' },
                                ]}
                            />*/}
                            <Button
                                component={Link}
                                to="/services"
                            >
                                Services
                            </Button>

                            <Button
                                component={Link}
                                to="/careers"
                            >
                                Careers
                            </Button>

                            <Button
                                component={Link}
                                to="/contact-us"
                            >
                                Contact us
                            </Button>
                        </Box>

                    </Toolbar>
                </Container>
            </AppBar>
        </ElevationScroll>
    )
}

export default Navbar;