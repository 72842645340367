import {BrowserRouter, Routes, Route} from 'react-router-dom';
import ScrollToTop from './components/config/ScrollToTop';


import Home from './Home';
import MainPage from './components/Home/MainPage';

import NotFound from './components/Pages/NotFound';
import AboutUs from './components/Home/AboutUs';
import VisionMission from './components/Home/VisionMission';
import Services from './components/Home/Services';
import ContactUsPage from './components/Home/ContactUsPage';
import Careers from './components/Home/Careers';

function App() {
  return (
    <BrowserRouter>
        <ScrollToTop>
            <Routes>
                <Route path="/" element={<Home />}>
                    <Route index element={<MainPage />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/vision-and-mission" element={<VisionMission />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/careers" element={<Careers />} />
                    <Route path="/contact-us" element={<ContactUsPage />} />
                </Route>

                <Route path="*" element={<NotFound />} />
                
            </Routes>
        </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
