import { Box, Link, Typography } from "@mui/material";

const NotFound=() => {
    return(
        <Box sx={{ height: '100vh', p: 12, textAlign:'center' }}>
            {<img src="/images/background/404-bg.png" height='75%' alt="page not found" />}
            <Typography variant='h1' color="text.secondary">Sorry!!! Goto <Link href='/'>Truelinesrcm.com</Link> while We are fixing it.</Typography>
        </Box>
    );
}

export default NotFound;