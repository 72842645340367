
import { Box, Grid, Container, Typography } from '@mui/material';



const Careers = () => {


    return (

        <Box>
            <Grid container>
                <Grid item xs={12}
                    sx={{
                        height: '350px',
                        backgroundImage: 'url(./images/background/about-bg.jpg)',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                    }}>

                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth="lg">
                        <Typography variant="h1" color="text.primary" sx={{ my: 5 }} data-aos="fade-up">
                            Careers
                        </Typography>
                        <Typography variant='body1' sx={{ mb: 1 }}>
                            To join our team, send your resume to truelinesrcmsolutions@gmail.com
                        </Typography>
                    </Container>

                </Grid>
            </Grid>



        </Box >



    );
}

export default Careers;