
import { Box, Grid, Container, Typography } from '@mui/material';



const Services = () => {


    return (

        <Box>
            <Grid container>
                <Grid item xs={12}
                    sx={{
                        height: '350px',
                        backgroundImage: 'url(./images/background/about-bg.jpg)',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                    }}>

                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth="lg">
                        <Typography variant="h1" color="text.primary" sx={{ mt: 5, mb:3 }} data-aos="fade-up">
                            Billing and AR Services
                        </Typography>
                        <Typography variant='body1' color="text.secondary" sx={{ mb: 1 }} data-aos="fade-up">We provide end to end revenue cycle process utilizing multiple billing systems including
                            Epic, Kareo, GE centricity, Medgen, IDX, Allscripts, eClinicalWorks, Advanced MD, Criterions,
                            Labgen and Blue EHR. Given below are the services offered:
                        </Typography>
                        <Typography variant='body1' color="text.secondary" sx={{ mb: 1 }} data-aos="fade-up">
                            Patient Registration and Scheduling.
                        </Typography>
                        <Typography variant='body1' color="text.secondary" sx={{ mb: 1 }} data-aos="fade-up">
                            Insurance Verification, Eligibility and Benefits Checking.
                        </Typography>
                        <Typography variant='body1' color="text.secondary" sx={{ mb: 1 }} data-aos="fade-up">
                            Demographic Data Entry &amp; Charge Capture
                        </Typography>
                        <Typography variant='body1' color="text.secondary" sx={{ mb: 1 }} data-aos="fade-up">
                            Medical Coding
                        </Typography>
                        <Typography variant='body1' color="text.secondary" sx={{ mb: 1 }} data-aos="fade-up">
                            Claims Transmission
                        </Typography>
                        <Typography variant='body1' color="text.secondary" sx={{ mb: 1 }} data-aos="fade-up">
                            Payment Posting
                        </Typography>
                        <Typography variant='body1' color="text.secondary" sx={{ mb: 1 }} data-aos="fade-up">
                            Accounts Receivable and Denial Management
                        </Typography>
                        <Typography variant='body1' color="text.secondary" sx={{ mb: 1 }} data-aos="fade-up">
                            Correspondence Follow Up
                        </Typography>
                        <Typography variant='body1' color="text.secondary" sx={{ mb: 1 }} data-aos="fade-up">
                            Self-Pay Follow Up
                        </Typography>
                        <Typography variant='body1' color="text.secondary" sx={{ mb: 1 }} data-aos="fade-up">
                            Collections
                        </Typography>
                        <Typography variant='body1' color="text.secondary" sx={{ mb: 1 }} data-aos="fade-up">
                            Dashboard Reporting
                        </Typography>
                        <Typography variant='body1' color="text.secondary" sx={{ mb: 1 }} data-aos="fade-up">
                            Physician Credentialing &amp; Enrolment
                        </Typography>
                        <Typography variant="h1" color="text.primary" sx={{ my: 3 }} data-aos="fade-up">
                            Front-End Fixes
                        </Typography>
                        <Typography variant='body1' color="text.secondary" sx={{ mb: 1 }} data-aos="fade-up">
                            TrueLines RCM Solutions prevents missed revenue opportunities by providing services such
                            as insurance verification and pre-authorizations to prevent claim denials.
                        </Typography>
                        <Typography variant="h1" color="text.primary" sx={{ my: 3 }} data-aos="fade-up">
                            Transaction Processing
                        </Typography>
                        <Typography variant='body1' color="text.secondary" sx={{ mb: 1 }} data-aos="fade-up">
                            TrueLines RCM Solutions custom solutions for charge capture and electronic and paper
                            claims submission help you bring in valuable revenue.</Typography>
                        <Typography variant="h1" color="text.primary" sx={{ my: 3 }} data-aos="fade-up">
                            A/R Management
                        </Typography>
                        <Typography variant='body1' color="text.secondary" sx={{ mb: 1 }} data-aos="fade-up">
                            Approximately three to seven percent of insurance claims are underpaid. TrueLines RCM
                            Solutions combats payor complexities and rising out-of-patient costs that pose challenges
                            for providers.
                        </Typography>
                    </Container>

                </Grid>
            </Grid>



        </Box >



    );
}

export default Services;